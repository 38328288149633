<template>
    <div>
        <template v-if="updateMode && !listMode">
            <v-btn :class="isPosted != 1 ? 'ml-3' : 'ml-0'" :loading="loading.url" small @click.stop="printInvoice()">
                <v-icon small>far fa-file-pdf</v-icon>
                <span class="ml-1">{{ $t('message.invoice') }}</span>
            </v-btn>
            <v-btn :loading="loading.urlDeliveryOrder" small class="ml-3" @click.stop="printInvoice('do')">
                <v-icon small>far fa-file-pdf</v-icon>
                <span class="ml-1" v-if="officeId == 21">{{ $t('message.packingList') }}</span>
                <span class="ml-1" v-else>{{ $t('message.deliveryOrder') }}</span>
            </v-btn>
            <v-btn :loading="loading.urlReleaseOrder" small class="ml-3" @click.stop="printInvoice('ro')">
                <v-icon small>far fa-file-pdf</v-icon>
                <span class="ml-1">{{ $t('message.releaseOrder') }}</span>
            </v-btn>

            <!--Specific for Indonesia only-->
            <template v-if="officeId == 21">
                <v-btn :loading="loading.urlTravelDocument" small class="ml-3" @click.stop="printOtherDocument('id_travel_document')">
                    <v-icon small>far fa-file-pdf</v-icon>
                    <span class="ml-1">{{ $t('message.indonesia.travelDocument') }}</span>
                </v-btn>
                <v-btn :loading="loading.urlCompanyNote" small class="ml-3" @click.stop="printOtherDocument('id_company_note')">
                    <v-icon small>far fa-file-pdf</v-icon>
                    <span class="ml-1">{{ $t('message.indonesia.companyNote') }}</span>
                </v-btn>
                <v-btn :loading="loading.urlSelfDeclaration" small class="ml-3" @click.stop="printOtherDocument('id_self_declaration')">
                    <v-icon small>far fa-file-pdf</v-icon>
                    <span class="ml-1">{{ $t('message.indonesia.selfDeclaration') }}</span>
                </v-btn>
            </template>

        </template>
        <template v-if="updateMode && listMode">
            <v-list-item class="font-sm" @click.stop="printInvoice()">
                <v-progress-circular :size="20" :width="2" indeterminate color="red lighten-1" class="mr-2" v-if="loading.url"/>
                <v-icon color="red lighten-1" small class="mr-2" v-else>fas fa-file-pdf</v-icon>
                <span>{{ $t('message.invoice') }}</span>
            </v-list-item>
            <v-list-item class="font-sm" @click.stop="printInvoice('do')">
                <v-progress-circular :size="20" :width="2" indeterminate color="red lighten-1" class="mr-2" v-if="loading.urlDeliveryOrder"/>
                <v-icon color="red lighten-1" small class="mr-2" v-else>fas fa-file-pdf</v-icon>
                <span v-if="officeId == 21">{{ $t('message.packingList') }}</span>
                <span v-else>{{ $t('message.deliveryOrder') }}</span>
            </v-list-item>
            <v-list-item class="font-sm" @click.stop="printInvoice('ro')">
                <v-progress-circular :size="20" :width="2" indeterminate color="red lighten-1" class="mr-2" v-if="loading.urlReleaseOrder"/>
                <v-icon color="red lighten-1" small class="mr-2" v-else>fas fa-file-pdf</v-icon>
                <span>{{ $t('message.releaseOrder') }}</span>
            </v-list-item>
        </template>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {api} from "Api";
// import {log, makeId} from "Helpers/helpers";

export default {
    name: "PrintLocalSaleButtons",
    props: ['localSaleId','updateMode','listMode','isPosted','version','officeId'],
    data() {
        return {
            loading: {
                url: false,
                urlDeliveryOrder: false,
                urlReleaseOrder: false,
                urlTravelDocument: false,
                urlCompanyNote: false,
                urlSelfDeclaration: false
            }
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
    },
    methods: {
        getPrintPdfUrl(document = null) {
            return new Promise((resolve, reject) => {

                let urlComponent = "/print-url/wh-invoice/"
                if(document === 'do') urlComponent = "/print-url/wh-invoice-do/"
                if(document === 'ro') urlComponent = "/print-url/wh-invoice-ro/"
                if(document === 'id_travel_document') urlComponent = "/print-url/wh-invoice-id-travel-document/"
                if(document === 'id_company_note') urlComponent = "/print-url/wh-invoice-id-company-note/"
                if(document === 'id_self_declaration') urlComponent = "/print-url/wh-invoice-id-self-declaration/"

                api
                    .get(urlComponent + this.localSaleId)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        printInvoice(document = null) {
            if(document === 'do') {
                this.loading.urlDeliveryOrder = true
            } else if(document === 'ro') {
                this.loading.urlReleaseOrder = true
            } else {
                this.loading.url = true
            }
            this.getPrintPdfUrl(document)
                .then(response => {
                    if(response.data.status === 'success') {
                        let tab = window.open(response.data.url, '_blank');
                        tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                        this.openedTabs.push(tab.name)
                    } else {
                        this.$toast.error(this.$t('message.errors.pdfError'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            })
                    }
                    if(this.loading.urlDeliveryOrder) this.loading.urlDeliveryOrder = false;
                    if(this.loading.urlReleaseOrder) this.loading.urlReleaseOrder = false;
                    if(this.loading.url) this.loading.url = false;
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.pdfError'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        })
                    if(this.loading.urlDeliveryOrder) this.loading.urlDeliveryOrder = false;
                    if(this.loading.urlReleaseOrder) this.loading.urlReleaseOrder = false;
                    if(this.loading.url) this.loading.url = false;
                })
        },
        printOtherDocument(document = null)
        {
            if(document === 'id_travel_document') {
                this.loading.urlTravelDocument = true
            } else if(document === 'id_company_note') {
                this.loading.urlCompanyNote = true
            } else if(document === 'id_self_declaration') {
                this.loading.urlSelfDeclaration = true
            }
            this.getPrintPdfUrl(document)
                .then(response => {
                    if(response.data.status === 'success') {
                        let tab = window.open(response.data.url, '_blank');
                        tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                        this.openedTabs.push(tab.name)
                    } else {
                        this.$toast.error(this.$t('message.errors.pdfError'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            })
                    }
                    if(this.loading.urlTravelDocument) this.loading.urlTravelDocument = false;
                    if(this.loading.urlCompanyNote) this.loading.urlCompanyNote = false;
                    if(this.loading.urlSelfDeclaration) this.loading.urlSelfDeclaration = false;
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.pdfError'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        })
                    if(this.loading.urlTravelDocument) this.loading.urlTravelDocument = false;
                    if(this.loading.urlCompanyNote) this.loading.urlCompanyNote = false;
                    if(this.loading.urlSelfDeclaration) this.loading.urlSelfDeclaration = false;
                })

        }
    }
}
</script>

<style scoped>

</style>